// extracted by mini-css-extract-plugin
export var page = "index-module--page--AO1Ek";
export var mainheading = "index-module--mainheading--RFhM+";
export var arrowicon = "index-module--arrowicon--yO-3I";
export var videocontainer = "index-module--videocontainer--Mmc+I";
export var video = "index-module--video--bMqvN";
export var info = "index-module--info--F7PdK";
export var servicescontainer = "index-module--servicescontainer--jksWo";
export var services = "index-module--services--ZyMT+";
export var servicelist = "index-module--servicelist--X0bWm";
export var contact = "index-module--contact--dhaWr";
export var clients = "index-module--clients--j9RQU";
export var clientheader = "index-module--clientheader--Ac5BB";
export var imagecarousel = "index-module--imagecarousel--D041m";