import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const CarouselLogo = ({ client }) => {
  const clientLogo = getImage(client)

  return (
    <GatsbyImage image={clientLogo} alt={client.title} />
  )
}

export default CarouselLogo